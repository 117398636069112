import React from 'react';
import { AppBar, Toolbar, Box, Button } from '@mui/material';
import clearbiz5 from '../../assets/clearbiz5.png'; // Ensure the correct extension
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: 'white', color: 'black', boxShadow: 'none' }}>
      <Toolbar>
        <img src={clearbiz5} alt="ClearBiz Logo" style={{ width: '150px', marginRight: 'auto'}} />
        <Box style={{ flexGrow: 1 }} />
        <Link to="/Login" style={{ textDecoration: 'none' }}>
          <Button 
            variant="contained" 
            style={{ 
              backgroundColor: '#0073b1', 
              color: 'white',
              borderRadius: '30px',
              height: '40px',
              '&:hover': {
                backgroundColor: '#005f8f',
              }
            }}
          >
            Login/SignUp
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
