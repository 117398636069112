import React from 'react';
import { Box, Card, CardContent, Typography, Grid,Paper,useTheme } from '@mui/material';
import TrustIcon from '@mui/icons-material/VerifiedUser';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RiskIcon from '@mui/icons-material/Security';
import CollaborationIcon from '@mui/icons-material/GroupWork';

const benefitsData = [
  { title: 'Enhanced Trust', icon: <TrustIcon sx={{ fontSize: 50, color: '#0073b1' }} />, description: 'Build stronger relationships based on transparency and reliability.' },
  { title: 'Time and Cost Savings', icon: <CurrencyRupeeIcon sx={{ fontSize: 50, color: '#0073b1' }} />, description: 'Efficient processes leading to reduced operational costs and time savings.' },
  { title: 'Reduced Risk', icon: <RiskIcon sx={{ fontSize: 50, color: '#0073b1' }} />, description: 'Minimize potential risks through proactive management and planning.' },
  { title: 'Improved Collaboration', icon: <CollaborationIcon sx={{ fontSize: 50, color: '#0073b1' }} />, description: 'Foster a collaborative environment for better problem-solving and innovation.' },
];

const Benefits = () => {
  const theme = useTheme();

  return (
    <Paper elevation={6} sx={{ margin: theme.spacing(3), padding: theme.spacing(3), borderRadius: theme.shape.borderRadius,backgroundColor:'#357ec7' }}>
    <Box sx={{ flexGrow: 1, padding: 3, textAlign: 'center', backgroundColor: '#357ec7', backdropFilter: 'blur(10px)' }}>
      <Typography variant="h3" gutterBottom component="div" sx={{ marginBottom: 4, color: 'white', fontWeight: 'bold' }}>
        Benefits
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {benefitsData.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={3} key={benefit.title}>
            <Card variant="outlined" sx={{ 
              minHeight: 220, 
              background: '#fofff',
              boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.5)',
              backdropFilter: 'blur(5px)',
              WebkitBackdropFilter: 'blur(5px)',
              borderRadius: '15px',
              border: '1px solid rgba(255, 255, 255, 0.25)',
              color: '#0073b1',
              height: '100%',
              '&:hover': { 
                transform: 'scale(1.1)', 
                transition: 'transform 0.3s ease-in-out'
              } 
            }}>
              <CardContent sx={{ textAlign: 'center', paddingTop: 2 }} >
                <Typography sx={{color:'#0073b1'}}>{benefit.icon}</Typography>
                <Typography variant="h6" component="div" sx={{ marginTop: 2, fontWeight: 'medium'}}>
                  {benefit.title}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1, color: '#0073b1', fontStyle: 'italic' }}>
                  {benefit.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Paper>
  );
};

export default Benefits;
