import React from "react";
import './content.scss'

const TermsConditions = () => {
  return (
    <>
      <p className="termsHeading">Terms and Conditions</p>
      <hr className="separate1" />
      <div className="text-color">
        <p>
        This document is an electronic record in terms of Information 
        Technology Act, 2000 and published in accordance with the provisions 
        of the Information Technology (Intermediary Guidelines and Digital 
        Media Ethics Code) Rules, 2021 that require publishing the rules and 
        regulations, privacy policy and Terms of Use for access or usage of 
        ClearBiz web platform accessible at{" "}
        <a href=" https://www.ClearBiz.ai/">ClearBiz.ai</a> . The Platform is
        owned by CLEARBIZ SOFTWARE Solutions Private Limited.
        </p>
        <p>
        Your use of the ClearBiz and services and tools are governed by the 
        following terms and conditions (“Terms of Use”) as applicable to the 
        ClearBiz including the applicable policies which are incorporated 
        herein by way of reference. By mere use of ClearBiz, you shall be 
        contracting with Bhagyoday Business Solutions Private Limited, the 
        owner of the Platform. These terms and conditions including the 
        policies constitute your binding obligations with ClearBiz
        </p>
        <p>
        For the Terms of Use, wherever the context so requires 
        “You” or “User” shall mean any natural or legal person who has agreed 
        to become a buyer on Platform by providing data while registering on 
        the Platform as a registered user. The term “ClearBiz”, “We”, “Us”, 
        “Our” shall mean Bhagyoday Business Solutions Private Limited and its 
        affiliates
        </p>
        <p>
        When You use any of the services provided by Us through the Platform,
        you will be subject to the rules, guidelines, policies, terms, and 
        conditions applicable to such service, and they shall be deemed to be 
        incorporated into this Terms of Use and shall be considered as part 
        of this Terms of Use.{" "}
        </p>
        <p>
          We reserve the right, at Our sole discretion, to change, modify, add
          or remove portions of these Terms of Use, at any time without any
          prior written notice to You. You shall ensure to review these Terms of
          Use periodically for updates/changes. Your continued use of the
          Platform following the posting of changes will mean that You accept
          and agree to the revisions. As long as You comply with these Terms of
          Use, We grant You a personal, non-exclusive, non-transferable, limited
          privilege to enter and use the Platform. By impliedly or expressly
          accepting these Terms of Use, You also accept and agree to be bound by
          Our Policies including but not limited to the Privacy Policy, as
          amended from time to time. <br />
          Rule 3 of the Information Technology (Intermediary Guidelines and
          Digital Media Ethics Code) Rules, 2021
        </p>
        <h6 className="bold">1. REGISTRATION OF USERS</h6>
        <p>
          <span><b>1.1</b></span> Registration of Individuals
        </p>
        <p>
          <b>1.1.1.</b> You will need to sign-up with Us by filling the sign-up form
           available on the Platform. You will be required to provide the below
          mentioned information to and open a ClearBiz account (“Account”).
          ClearBiz, may, at its sole discretion, ask You to provide any further
          information as We may deem necessary for Account verification.
        </p>
        <p>Non-Exclusive list of information provided by You:</p>
        <ol type="i" className="listPadding">
          <li>PAN NUMBER – The Registered PAN NUMBER.</li>
          <li>
            Date Of Incorporation– The DOI provided shall be the same as the
            date mentioned in the GST registration certificate
          </li>
          <li>
            BUSINESS NAME– The Business Name provided shall be the same as the
            business name mentioned in the GST registration certificate.
          </li>
          <li>INDUSTRY – Provide the industry type of the business.</li>
          <li>PARTNER TYPE– Provide the type of Partner.</li>
          <li>
            COMPANY TYPE– The company name provided shall be the same as the
            company mentioned in the GST registration certificate.
          </li>
          <li>
            OWNER NAME– The owner name provided shall be the same as the name
            mentioned in the PAN CARD.
          </li>
          <li>
            MOBILE NUMBER– It is mandatory to provide your mobile number. As you
            can use our chatbot.
          </li>
          <li>EMAIL ID– It is mandatory to provide your email address.</li>
          <li>
            STATE, DISTRICT, CITY – The details provided shall be the same as
            the details mentioned in the GST registration certificate.
          </li>
          <li>
            SUBCRIPTION – User with subscription will be avail to get all the
            services provided by ClearBiz .
          </li>
        </ol>
        <p>
          <b>1.1.2</b> You must provide accurate and complete information to use tech
          Platform. Promptly inform Us of any changes to your information.
          Failure to provide correct information will result in account
          deactivation
        </p>
        <p>
          <b>1.1.3</b> Upon completing the above steps a user account will be created
          for you which will comprise of a company account relating to the data
          entered by you.
        </p>
        <p>
          <span><b>1.2</b></span> Verification
        </p>
        <p>
          <b>1.2.1</b> We may use automated/manual means to verify your individual and
          company credentials using PAN, Aadhaar, GST, etc through 3rd party
          services. The data will be deemed given by you for verification
          purpose and will be safely stored and used as per the terms of this
          agreement.
        </p>
        <p>
          <b>1.3</b> You must provide accurate and complete information to use the
          Platform. Promptly inform Us of any changes to your information.
          Failure to provide correct information will result in account
          deactivation
        </p>
        <p><b>1.4</b> Data Fetching using GSTIN API</p>
        <p><b>1.4.1</b> The platform utilizes the GSTIN API to fetch user data.</p>
        <p>
          <b>1.4.2</b> If the PAN or GSTIN is found, the platform provides the
          corresponding user details. If the PAN or GSTIN is not available, the
          platform fetches the details using the GSTIN API.
        </p>
        <p>
          <b>1.4.3</b> When a registered user searches for a PAN or GSTIN, the platform
          checks if the PAN or GSTIN is already registered.
        </p>
        <p><b>1.5</b> You agree to:</p>
        <ol type="i" className="listPadding">
          <li>
            Immediately notify ClearBiz of any unauthorised use / breach of your
            password or account and
          </li>
          <li>
            Ensure that you exit from your account at the end of each session.
          </li>
        </ol>
        <p>
          The Users utilise to meet and interact with one another for their
          transactions on the Platform.
        </p>
        <p>
          ClearBiz is not and cannot be a party to or control in any manner any
          transaction between ClearBiz’s Users.{" "}
        </p>
        <h6 className="bold">
          2. RAISING AND RESOLVING OF DISPUTES ON THE PLATFORM
        </h6>
        <p><b>2.1</b> Raising and Resolving of Disputes.</p>
        <p>
        <b>2.1.1</b> Dispute Initiation Users with low ratings have the option to
          initiate a "DISPUTE." Additionally, if someone unfamiliar or unknown
          to the user provides a rating, they can also raise a dispute. When
          initiating a "DISPUTE" on behalf of an authorized company, the
          following steps must be taken:
        </p>
        <ol type="i" className="listPadding">
          <li>Provide business transaction details.</li>
          <li>Issues caused by the party.</li>
        </ol>
        <p><b>2.2.</b>  Review and Confirmation</p>
        <p><b>2.2.1</b> Review Ratings and Reviews:</p>
        <p>
          Before accepting or declining a received rating and review for your
          company's services, it is imperative to carefully evaluate the
          feedback provided by the user.
        </p>
        <p><b>2.2.2</b> Accept or Decline:</p>
        <p>
          To proceed with the acceptance or declination of a received rating and
          review, follow the provided procedure.
        </p>
        <p><b>2.2.3</b> ClearBiz's Role:</p>
        <p>
          It's important to clarify that ClearBiz does not and cannot act as a
          party to, or exert any control over, the ratings and reviews provided
          by ClearBiz users for your company's services. We serve as a platform
          for facilitating these feedback mechanisms but do not participate in
          or influence them in any way.
        </p>
        <p>
          <b>2.3.</b> By agreeing to use the Platform, you accept communication from us
          and other users via text messages, emails, and notifications related
          to the Platform and its transactions. ClearBiz may use any other form
          or channel of communication that We, as well as the other Users may
          deem fit. Users must avoid sending spam messages.
        </p>
        <p>
          <b>2.4.</b> ClearBiz is not responsible for any non-performance or breach of
          any contract entered into between entities. ClearBiz explicitly
          disclaims any guarantee, express or implied, regarding the performance
          of transactions undertaken on the Platform by the involved entities.
        </p>
        <p>
          <b>2.5.</b> You agree that You are aware and responsible for all actions
          taking place through your Account.
        </p>
        <p>If You knowingly or negligently</p>
        <ol type="i" className="listPadding">
          <li>Grant any other person access to your Account,</li>
          <li>Permit them to transact on Your account, or</li>
          <li>
            Transact on any other person’s behalf or directions; whether by
            sharing one-time passwords or in any other manner, You shall
            continue to be fully responsible and liable for the transactions in
            your account.
          </li>
        </ol>
        <h6 className="bold">3. User Score</h6>
        <p>
          We will provide a performance score to the Users, determined on the
          basis of such parameters as We may deem fit and proper.
        </p>
        <h6 className="bold">4. Critical Dues</h6>
        <p>
          <b>4.1.</b> Users with a valid subscription on behalf of their authorised
          Company, reserve the right to name/tag a Company as a Defaulter by
          tagging the said Company’s transaction as ‘Critical Dues’ (whether
          contemplated, ongoing, or completed) on their own determination based
          on the facts and circumstances of each case.
        </p>
        <p>
          Users shall ensure that any such tagging is in compliance with the
          applicable laws and does not violate/breach these Terms of Use.
        </p>
        <p>
         <b>4.2.</b> The Users, on behalf of their authorised Company, while tagging
          any company under this Clause, will be required to upload the relevant
          documentary evidence supporting their claim for such tagging. The
          responsibility of uploading correct and legible documents will be on
          the Users. ClearBiz takes no responsibility for the correctness of the
          information uploaded by You.
        </p>
        <p>
          <b>4.3.</b> We will inform the Users about any ‘LOW RATING’ tag made against
          their authorised Company and the Company’s relevant transactions
          (whether contemplated, ongoing, or completed) entered into by them
          through text messages, email, notifications, or other relevant
          communication channels as we may deem fit.
        </p>
        <p>
          <b>4.4.</b> ClearBiz will have the right and authority to share such
          information with respect to the same on the Platform.
        </p>
        <p>
         <b>4.5.</b> ClearBiz will have the obligation to disclose any details with
          respect to the Company and its authorised persons and/or the
          transactions if the same is required by an order of any adjudicating
          authority or governmental authority.
        </p>
        <p>
          <b>4.6.</b> It is once again clarified that ClearBiz has no responsibility to
          verify the information/documents provided by a User under this Clause.
          No claims shall be pending against ClearBiz for any actions taken
          pursuant to the declaration/tagging under this Clause.
        </p>
        <h6 className="bold"> 5. Incorrect/Misleading Information</h6>
        <p>
          You must ensure that the information/Documents provided by You under
          this Clause is correct, complete, and not misleading.
        </p>
        <h6 className="bold">6. RESPONSIBILITIES</h6>
        <p><b>6.1.</b> Our Responsibilities towards the Users</p>
        <p>
          <b>6.1.1.</b> We will exercise reasonable skill and care with respect to the
          access of the Platform by the Users. However, You acknowledge and
          agree that the use of the Platform is subject to the following
          limitations and disclaimers:
        </p>
        <p>
          Although We have asked all users to use the Platform responsibly, We
          cannot guarantee the accuracy, integrity or quality of any information
          You view or receive from the Users that are registered with Us. This
          includes any information contained about the details of the Users or
          the quality of support You may receive from them. You also acknowledge
          and agree that the information is intended to be indicative only and
          will not form the basis of a binding contract between You and the
          other Users. If You choose to instruct a User to provide any services
          to You, We strongly recommend You to execute a written agreement with
          them clearly detailing the work to be done and the prices to be paid.
          We will not be a party to any such agreement or contract and will not
          under any circumstances have any responsibility and/ or liability to
          You for the performance or quality of any services which You ask the
          Users to carry out.
        </p>
        <p>
          ClearBiz or the Platform will not be liable for the accuracy, quality,
          or the binding nature of any exchanged information. A written
          agreement is recommended for any services rendered, and the Platform
          is not responsible for user performance or quality of work that may be
          promised basis the written agreement.
        </p>
        <p>
          You acknowledge and agree that We have the absolute discretion to
          determine the registration and listing criteria. We do not provide any
          guarantee that You will be invited to receive any particular volume of
          services or that You will gain any extra work by utilising Our
          Platform. You acknowledge and agree that We do not check or verify the
          details of any service bookings and do not guarantee the accuracy,
          integrity or quality of any information which is posted by third
          parties.
        </p>
        <p><b>6.2.</b> Responsibilities of Users</p>
        <p>
          <b>6.2.1.</b> You agree to abide by the Terms of Use when using the Platform.
          You shall be solely responsible for checking and verifying the details
          of any services accepted/ rendered on the Platform by You and the
          terms of any support thereof. You shall be responsible for complying
          with all applicable laws and regulations in Your dealings with other
          Users and for the performance and quality of any support which You
          agree to provide to/ receive from a User.
        </p>
        <p>
          <b>6.2.2.</b> By registering as a User and by using the Platform, You agree
          to indemnify and hold Us harmless from all costs, losses or claims
          which may result from any information You submit or transmit via the
          Platform or from any support which You agree to provide to/ receive
          from any User.
        </p>
        <p>
          You are prohibited from posting or transmitting to or through this
          Website:
        </p>
        <ol className="listPadding">
          <li>
            Any unlawful, threatening, libellous, defamatory, obscene,
            pornographic or other material or content that would violate rights
            of publicity and/or privacy or that would violate any law or that
            harms minors in any way.
          </li>
          <li>
            Any commercial material or content (including, but not limited to,
            solicitation of funds, advertising, or marketing of any good or
            services).
          </li>
          <li>
            Any material or content that infringes, misappropriates or violates
            any copyright, trademark, patent right or other proprietary right of
            any third party;
          </li>
          <li>
            Contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource.{" "}
          </li>
          <li>
            Threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting to any other
            nation.
          </li>
          <li>Impersonates another person.</li>
          <li>
            Is illegal in any other way. You shall be solely liable for any
            damages resulting from any violation of the foregoing restrictions,
            or any other harm resulting from Your posting of content to this
            Website
          </li>
        </ol>
        <p><b>6.3.</b> Partner Consent and Data Responsibility</p>
        <p>
          <b>6.3.1.</b> In cases where a registered user intends to associate another
          individual or company as a partner on the ClearBiz, the following
          conditions apply:{" "}
        </p>
        <ol className="listPadding">
          <li>
            User must obtain explicit and documented consent from the Partner,
            acknowledging that the Partner will be rated, and information about
            their business will be shared on the platform. This consent can be
            generated within the app as an email consent, which can be utilized
            as a softcopy or hardcopy as per user preference
          </li>
          <li>
            We will verify the authenticity and validity of the email consent
            provided by users. However, it is the responsibility of users to
            ensure that the consent obtained from their partners is accurate and
            complies with applicable laws and regulations.{" "}
          </li>
          <li>
            ClearBiz does not assume any responsibility for the data or
            information provided by User about the Partner. User shall be solely
            responsible for the accuracy and legality of the data shared about
            the Partner.
          </li>
        </ol>
        <p> You represent and warrant that:</p>
        <ol type="a" className="listPadding">
          <li>
            You are duly incorporated or established under the laws of Your
            jurisdiction and have all requisite power and authority to own and
            operate Your business.
          </li>
          <li>
            You have the full legal capacity and power to enter into, exercise
            Your rights under, and{" "}
          </li>
          <li>
            You have duly accepted these Terms, which form a legal, valid and
            binding obligation, enforceable in accordance with its clauses.
          </li>
        </ol>
        <h6 className="bold">7. USERNAME AND PASSWORD</h6>
        <p>
          After the registration, a username and password (“ID”) will be
          generated for the use of the Platform by You, which You must keep
          confidential. You shall be solely responsible for preventing any
          unauthorised use of the ID and notify Us in case any theft or
          unauthorised use of the ID takes place. We reserve the right to
          suspend and/ or block Your ID if the same is used in contravention of
          these Terms of Use. ClearBiz has no obligation to You in case of any
          unauthorised use of Your ID.
        </p>
        <h6 className="bold">8. USE OF PLATFORM</h6>
        <p>
          <b>8.1.</b> The Platform allows the registered users to create, edit and
          submit necessary information including creating User profile, posting
          details of the services and responding and updating the status of the
          services. We do not seek to monitor or control the submission of
          information. However, We do reserve the right to delete, move and edit
          any material submitted where We consider it necessary to do so.{" "}
        </p>
        <p>
          <b>8.2.</b> You agree to comply with all applicable laws and regulations when
          using the Platform and are solely responsible for all the information
          You submit for inclusion or transmission.{" "}
        </p>
        <p><b>8.3.</b> You must not post or transmit any material that </p>
        <ol type="i" className="listPadding">
          <li>Is fraudulent, dishonest or misleading; </li>
          <li>
            Is unlawful, harassing, libellous, abusive, threatening, harmful,
            vulgar, obscene, or otherwise objectionable or breaches any law;
          </li>
          <li>
            Encourages conduct that constitutes a criminal offence, gives rise
            to civil liability or otherwise breaches any applicable laws,
            regulations or code of practice;{" "}
          </li>
          <li>Infringes the copyright or other rights of any third party; </li>
          <li>
            {" "}
            Is technically harmful (including, without limitation, computer
            viruses or other malicious software or harmful data).
          </li>
        </ol>
        <p>
          <b>8.4.</b> You grant ClearBiz a royalty-free, non-exclusive licence to use,
          reproduce, modify, translate, make available and distribute the
          material for the purpose of operating the Platform.
        </p>
        <h6 className="bold">7. ALERTS/NOTIFICATIONS</h6>
        <p>
          <b>7.1.</b> We may from time to time provide automatic alerts/notifications
          and payment related alerts linked with Your Account on the Platform.
          Further, automatic alerts may be sent to you following certain changes
          to Your Account or information, such as changes in your registration
          information. By accepting the terms and conditions and/or using the
          Services, you accept that we may send the alerts to your registered
          mobile phone number and /or registered email id.
        </p>
        <p>
          <b>7.2.</b> You acknowledge that the alerts will be received only if the
          mobile phone is in “On” mode to receive the SMS. If the mobile phone
          is in “Off” mode, then you may not get/get after delay any alerts sent
          during such period. Electronic alerts will be sent to the email
          address provided by you as your primary email address for the
          Services. If your email address changes, you are responsible for
          informing us of that change. You can also choose to have alerts sent
          to a mobile device that accepts text messages. Changes to your email
          address or mobile number will apply to all your alerts.
        </p>
        <p>
          <b>7.3.</b> We will make best efforts to provide the Service and it shall be
          deemed that you shall have received the information sent from us as an
          alert on your registered mobile phone number or email id and we shall
          not be under any obligation to confirm the authenticity of the
          person(s) receiving the alert. You cannot hold us liable for
          non-availability of the service in any manner whatsoever.
        </p>
        <p>
          <b>7.4.</b> You further acknowledge that You shall have the obligation to
          inform us about any change in your mobile phone number or e-mail
          address. Alerts /notification will be sent to your new mobile number
          or e-mail address after registering and linking it with your Account.
          You acknowledge that the SMS service or email service provided by us
          is an additional facility provided for your convenience and that it
          may be susceptible to error, omission and/ or inaccuracy. In the event
          you observe any error in the information provided in the alert, you
          shall immediately inform us about the same. We will make best possible
          efforts to rectify the error as soon as possible.
        </p>
        <h6 className="bold">8. GENERAL TERMS AND CONDITIONS</h6>
        <p>
          <b>8.1.</b> You shall register to become a user of the Platform only if You
          are of the age of 18 or above and can enter into binding contracts as
          per the applicable laws.{" "}
        </p>
        <p>
          <b>8.2.</b> You are responsible for maintaining the secrecy of Your
          passwords, login and account information. You will be responsible for
          all use of the Platform and the services availed by You and anyone
          using Your password and login information (with or without our
          permission). You are responsible for maintaining the confidentiality
          of any login information and secure access credentials associated with
          Your ClearBiz Account. Accordingly, You are responsible for all
          activities that occur under Your account/in using Your secure
          credentials and ClearBiz shall not be liable for any such change or
          action performed by using Your secure credentials on the Platform.
        </p>
        <p>
          <b>8.3.</b> You also agree to provide true, accurate, current and complete
          information about Yourself as and when prompted by the Platform. If
          You provide any information that is untrue, inaccurate, not updated or
          incomplete (or becomes untrue, inaccurate or incomplete), or ClearBiz
          has reasonable grounds to suspect that such information is untrue,
          inaccurate, not updated or incomplete, ClearBiz shall have the right
          to suspend or terminate Your account and/or refuse any and all current
          or future use of the Website (or any portion thereof) or Services in
          connection thereto.
        </p>
        <p>
          <b>8.4.</b> You agree that ClearBiz shall not be responsible for any
          delivery, after-sales service, payment, invoicing or collection,
          customer enquiries (not limited to sales enquiries), technical support
          maintenance services and/or any other obligations or services relating
          to or in respect of Your products or services. Such obligations shall
          be Your sole responsibility. You shall indemnify ClearBiz against any
          claim arising from such services or obligations and shall bear any and
          all expenses and/or costs relating thereto.
        </p>
        <p>
          <b>8.5.</b> You agree not to use the Platform for any purpose that is
          unlawful, illegal or forbidden by these Terms, or any local laws that
          might apply to You.{" "}
        </p>
        <p>
          <b>8.6.</b> As the Platform is in operation within India, while using the
          Platform, You shall agree to comply with laws that apply in India, as
          and when such laws may be amended.{" "}
        </p>
        <p>
          <b>8.7.</b> We may, at our sole discretion, at any time and without advance
          notice or liability, suspend, terminate or restrict Your access to all
          or any component of the Platform.{" "}
        </p>
        <h6 className="bold">9. MODIFICATIONS TO THE PLATFORM</h6>
        <p>
          <b>9.1.</b> We reserve the right to add, change, modify, discontinue or
          remove features from Our Platform at any time without cause,
          temporarily or permanently with or without any notice to You. You
          agree that We shall not be liable to You or to any third party for any
          modification, suspension or discontinuance of Our Platform.
        </p>
        <p>
          <b>9.2.</b> You shall not modify, adapt or hack the Platform, or otherwise
          attempt to gain unauthorised access to the Platform or their related
          systems or networks
        </p>
        <h6 className="bold">10. TERMINATION OF SERVICES</h6>
        <p>
          We may suspend or cancel Your registration and/ or terminate Your
          access to the Platform if You are in breach of these Terms of Use or
          have made any improper use of the Platform. You may cancel Your
          registration by giving Us a prior written notice of at least 90
          (ninety) days. If You do so, You must stop using the Platform. The
          suspension, cancellation or termination of Your registration and Your
          right to use the Platform shall not affect either party’s statutory
          right or liabilities.
        </p>

        <h6 className="bold">11. INTELLECTUAL PROPERTY RIGHTS</h6>
        <p>
          <b>11.1</b> Unless otherwise stated, the intellectual property rights in the
          Platform (including without limitation all content, materials and
          technology used or appearing or transmitted through it) belongs to
          ClearBiz, or with Bagyodhay Business Solutions Private Limited, being
          the owner of the Platform, or with our licensors.
        </p>
        <p>
          <b>11.2.</b> Subject to the terms and conditions of these Terms of Use, We
          hereby grant You a personal, non- transferable, non-sublicensable and
          non-exclusive right and licence to use the Platform solely in
          accordance with these Terms of Use, provided that You shall not (and
          shall not allow any third party to) copy, modify, create a derivative
          work of, reverse engineer, reverse assemble or otherwise attempt to
          discover any source code, or sell, assign, sublicense or otherwise
          transfer any right in the Platform.
        </p>
        <p>
          <b>11.3.</b> The right and licence to use granted to You under these Terms of
          Use shall not amount to any assignment of the intellectual property
          rights in the Platform. ClearBiz, and/or Bagyodhay Business Solutions
          Private Limited shall continue to be owners of all intellectual
          property rights herein.{" "}
        </p>
        <h6 className="bold">12. CONFIDENTIALITY</h6>
        <p>
          <b>12.1</b> You shall treat this Agreement as confidential. You shall not,
          except with Our prior written approval, disclose or make any public or
          other announcements of any aspect covered by these Terms of Use and
          any other relevant information and documents, the contents of which
          are made available to You with respect to the use by you of the
          Platform, unless required to do so and to the extent necessary under
          these Terms of Use, on a need to know basis, while always ensuring
          that the persons to whom such information and documents are disclosed
          maintain the strictest confidentiality of the same.
        </p>
        <p>
          <b>12.2.</b> We reserve the right to disclose any information/ documents
          pertaining to the User and/ or any transaction on the Platform, in the
          event such information/ documents:
        </p>
        <p>
          <b>12.2.1.</b> Have ceased to be confidential without default on Our part.
        </p>
        <p>
          <b>12.2.2.</b> Have been received from a third party who did not receive it
          in confidence; and
        </p>
        <p>
          <b>12.2.3.</b> We are required by any court, government or other regulatory
          body to disclose, but only to the extent required by law.
        </p>
        <h6 className="bold">13. DISCLAIMERS</h6>
        <p>
          <b>13.1.</b> We endeavour to ensure that the information available on or
          through the use of the Platform is correct, up to date and accurate.
          We are mere service providers; You acknowledge and undertake that you
          are accessing the services on the Platform and rating at your own risk
          and are using your best and prudent judgement before entering into any
          actions through ClearBiz.
        </p>
        <p>
          <b>13.2.</b> Any content downloaded or otherwise obtained through the use of
          the Services is downloaded at your own risk, and you will be solely
          responsible for any damage to your property or loss of data that
          results from such download. No advice or information, whether oral or
          written, obtained by you from us or through the Services/ the use of
          the Platform will create any warranty not expressly stated herein.
        </p>
        <p>
          <b>13.3.</b> We will not be liable to you for any loss or damage whatsoever
          or howsoever caused or arising, directly or indirectly, including
          without limitation, as a result of loss of data; interruption or
          stoppage of access to payment gateway; interruption or stoppage of the
          Platform; non-availability of connectivity links/hyperlinks. Our sole
          obligation and your sole and exclusive remedy in the event of
          interruption in any use of the Platform or loss of use and/or access
          to the Platform, will be to use all reasonable endeavours to restore
          the use of the Platform and/or access as soon as reasonably possible.
        </p>
        <p>
          <b>13.4.</b> The Platform permits You to access and receive information
          supplied by third parties. The third parties submitting this
          information are responsible for ensuring that it is accurate and
          complies with all the relevant laws. We will not be responsible to You
          for the conduct of any such third party or for any error or inaccuracy
          in the information submitted by them. We make no promise that the
          Platform will meet Your requirements or that the Platform will be free
          of fault or continuously available.{" "}
        </p>
        <p>
         <b>13.5.</b> Your access to the Platform may occasionally be restricted to
          allow for repairs, maintenance, or the introduction of new features.
        </p>
        <p>
          <b>13.6.</b> We shall neither be liable nor responsible for any actions or
          inactions of entities nor any breach of conditions, representations or
          warranties by the sellers or manufacturers of the products and hereby
          expressly disclaim any all responsibility and liability in that
          regard.{" "}
        </p>
        <p>
        <b>13.7.</b> We shall not mediate or resolve any dispute or disagreement
          between You and the partners you have added. We further expressly
          disclaim any warranties or representations (express or implied) in
          respect of quality, suitability, accuracy, reliability, completeness,
          timeliness, performance, safety, merchantability, fitness for a
          particular purpose, or legality of the products listed or displayed or
          transacted or the content (including product or pricing information
          and/or specifications) on Platform.
        </p>
        <p>
        <b>13.8.</b> While we have taken precautions to avoid inaccuracies in
          content, all such content, information (including the price of
          products), software, products, services, and related graphics are
          provided as is, without warranty of any kind. At no time shall
          ClearBiz have any obligations or liabilities in respect of any
          transactions on Platform.
        </p>
        <p>
        <b>13.9.</b> ClearBiz has no obligation under Clause 3.3 of these Terms of
          Use and no liability to confirm/deny the tagging of ‘Low Rating’ given
          by a User on the Platform. ClearBiz purely functions as an
          intermediary for the storing of such information and as a User, You
          have no right to make any claims against ClearBiz or Bagyodhay
          Business Solutions Private Limited for any declaration/tagging done
          under Clause 3.3 and any consequences of such declaration/tagging.{" "}
        </p>
        <p>
        <b>13.10.</b> ClearBiz has no obligation, in any circumstances, to refund the
          fee(s) paid by the User under Clause 3.4 of these Terms of Use.{" "}
        </p>
        <p>
        <b>13.11.</b> The Terms of Use and Privacy Policy are in compliance with the
          laws of the Indian territory and all services provided on the Platform
          adhere to the relevant legal requirements. Nothing in this clause
          shall deem to be a guarantee of the current applicable laws. It is the
          User’s responsibility to stay up-to-date with the latest developments
          and/or any amendments to any of the laws applicable to the services
          used by the User on the Platform.
        </p>
        <h6 className="bold">14. INDEMNITY</h6>
        <p>
        <b>14.1.</b> You shall defend, indemnify, and hold harmless Us, Our affiliate
          companies, directors, associates, agents and officers from and against
          any loss, expenses, claims, actions or demands, including without
          limitation reasonable legal and accounting fees, arising out of or
          resulting from Your breach of these Terms of Use, Privacy Policy and
          any other policies that ClearBiz may require you to comply with, Your
          violation of any law, rules or regulations or the rights (including
          infringement of intellectual property rights) of a third party.{" "}
        </p>
        <p>
        <b>14.2.</b> We shall not provide notice to You of any such claim, suit, or
          demand. Further, We reserve the right to assume the exclusive defence
          and control of any matter which is subject to indemnification under
          this clause. In such case, You agree to cooperate with any reasonable
          requests assisting Our defence of such matter.
        </p>
        <p>
        <b>14.3.</b> The obligations contained in this clause shall survive the
          termination of the Terms of Use with You and Your use of the Platform
          and any services rendered therein.
        </p>
        <h6 className="bold">15. LIMITATION OF LIABILITY</h6>
        <p>
          <b>15.1.</b> To the fullest extent permitted by law, We exclude all liability
          to You for any damage to property, loss of profit, loss of
          opportunity, loss of earnings, loss of anticipated earnings or loss of
          data or for any indirect or consequential losses howsoever arising out
          of or in connection with Your use of the Services or any fault or
          problem relating to or any content received via the Platform. Nothing
          in these Terms of Use shall exclude or limit Our liability for death
          or personal injury due to Our negligence or for fraudulent
          misrepresentation or for any other liability which cannot be excluded
          or limited under the applicable laws. Your statutory rights as a
          consumer are not affected by these Terms of Use.
        </p>
        <p>
        <b>15.2.</b> Notwithstanding anything contained in these Terms of Use, under
          no circumstances shall Our aggregate liability for all loss, cost,
          damage, and expense whether for negligence or breach of these Terms of
          Use or any case whatsoever exceed the current sale price of the
          product/service.
        </p>
        <p>
        <b>15.3.</b> In no event shall ClearBiz be liable for any indirect, punitive,
          incidental, special, consequential damages or any other damages
          resulting from:
        </p>
        <p>
          The use or the inability to use the Services or Products; unauthorised
          access to or alteration of the user's transmissions or data; breach of
          condition, representations, or warranties by the manufacturer/sellers
          under any written agreement between You and such
          manufacturers/sellers; and, any other matter relating to the services
          including, without limitation, damages for loss of use, data or
          profits, arising out of or in any way connected with the use or
          performance of the Platform or Service.
        </p>
        <p>
        <b>15.4.</b> ClearBiz shall not be held responsible for non-availability of
          ClearBiz during periodic maintenance operations or any unplanned
          suspension of access to the ClearBiz. The User understands and agrees
          that any material and/or data downloaded at ClearBiz is done entirely
          at Users own discretion and risk and they will be solely responsible
          for any damage to their mobile or loss of data that results from the
          download of such material and/or data.{" "}
        </p>
        <p>
        <b>15.5.</b> ClearBiz shall not be liable for any dispute or disagreement
          between Users.
        </p>
        <h6 className="bold">16. FORCE MAJEURE</h6>
        <p>
          In the event that the performance of any of Our obligations pursuant
          to these Terms of Use is prevented, hindered or delayed by reason of
          epidemic, pandemic, fire, flood, earthquake, explosion or other
          casualty or accident or act of God, war or other violence, or any
          applicable law, order proclamation, regulation, ordinance, demand or
          requirement of any governmental or regulatory authority (collectively
          “Force Majeure Event”), then We will be excused for such
          non-performance, hindrance or delay, as applicable, from Our
          obligations hereunder, to the extent that they are affected by the
          Force Majeure Event.
        </p>

        <h6 className="bold">17. NOTICES</h6>
        <p>
          All communication shall be sent to Us/ You via email or at the postal
          address provided.
        </p>
        <p>
          All legal notices to Us/You shall be sent only to the registered
          postal address provided; the notice will be considered delivered upon
          acknowledgment of delivery by Us.
        </p>
        <h6 className="bold">18. GOVERNING LAW</h6>
        <p>
          These Terms of Use shall be governed by and construed in accordance
          with the laws of India. The parties subject themselves to the
          exclusive jurisdiction of the courts at Adilabad, Telangana, India.
        </p>

        <h6 className="bold">19. DISPUTE RESOLUTION</h6>
        <p>
          Any dispute in respect of all or any of the provisions of these Terms
          of Use shall be resolved amicably within 15 (fifteen) days of
          notifying the same to the other party and in the event neither party
          reaches an agreement, the dispute should be resolved by Arbitration
          which shall be referred to a Sole Arbitrator in accordance with the
          provisions of the Arbitration and Conciliation Act 1996. The place of
          arbitration shall be Adilabad, Telangana, India and the language used
          shall be English.
        </p>
        <h6 className="bold">20. ENTIRE AGREEMENT</h6>
        <p>
          The Terms of Use and the Privacy Policy (as and when amended)
          represent the entire agreement between You and Us in relation to the
          use of the Platform and shall supersede any prior agreement,
          understanding or arrangement between Us, whether oral or in writing.
          Further, You acknowledge and agree that, You have not relied on any
          representation, undertaking, promise or implied any warranty, whether
          conveyed orally or in writing, except as expressly stated herein.
        </p>

        <h6 className="bold">21. CHANGE IN TERMS OF USE</h6>
        <p>
          We reserve the right to change these Terms of Use at any time. You are
          expected to check and get acquainted with these Terms of Use from time
          to time.
        </p>

        <h6 className="bold">22. WAIVER</h6>
        <p>
          No delay in enforcing any provision of the Terms of Use will be
          construed to be a waiver of any rights under that provision by Us.
        </p>
        <h6 className="bold">23. ASSIGNMENT</h6>
        <p>
          You shall not assign any of Your rights or obligations under these
          Terms of Use without Our prior written consent.
        </p>
        <h6 className="bold">24. SEVERABILITY</h6>
        <p>
          If any provision of these Terms of Use is held by a court of competent
          jurisdiction to be void, invalid, unenforceable or illegal, the
          remaining provisions shall remain in full force and effect.
        </p>
      </div>
    </>
  );
};

export default TermsConditions;
