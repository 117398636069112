import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/clearbiz2.png"; // Adjust the path as needed
import review3 from '../../assets/review3.png'; 
import { getSubscriptionAction } from "../../store/actions";
import Termsandpolicy from "../terms_policy";
import PropTypes from 'prop-types';
const defaultTheme = createTheme();
 
function SubscriptionCard({ item, getcardBackgroundColor, index, isSubscriptionActive, handleButtonClick, getButtonBackgroundColor }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <CardHeader
        title={`${item.type}: ${item.name}`}
        titleTypographyProps={{ align: "center" }}
        subheaderTypographyProps={{ align: "center" }}
        sx={{ backgroundColor: getcardBackgroundColor(index) }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            sx={{
              textDecoration: "line-through",
              color: "#888888",
            }}
          >
            {`₹${item.price}/year`}
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            color="#d90429"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {`Save ${Math.round(((item.price - item.offer_price) / item.price) * 100)}% on the first-year subscription`}
          </Typography>
          <Typography
            component="h2"
            variant="h4"
            color="text.primary"
          >
            {`₹${item.offer_price}/year`}
          </Typography>
          <ul style={{ textAlign: "center" }}>
            {item.description.map((descItem, descIndex) => (
              <li key={descItem}>{descItem}</li>
            ))}
          </ul>
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: getButtonBackgroundColor(index),
              mt: 3,
              color: 'white',
            }}
            onClick={() => handleButtonClick(item.offer_price, item.id)}
            disabled={isSubscriptionActive === 'true'}
          >
            {item.display_text}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
 
export default function Pricing() {
  const [is_company, setIsCompany] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successData: subscriptionData } =
    useSelector((state) => state.getSubscriptionData) || {};
  const handleButtonClick = (price, id) => {
    localStorage.setItem("paymentId", id);
    localStorage.setItem("offer_Prize", price);
    navigate("/payment");
  };
 
  useEffect(() => {
    dispatch(getSubscriptionAction());
    setIsCompany(localStorage.getItem("is_company"));
  }, [dispatch]);
 

 
  const isSubscriptionActive = localStorage.getItem("is_subscription_active");
 
  const getcardBackgroundColor = (index) => {
    switch (index) {
      case 0:
        return "#a3cef1";
      case 1:
        return "#34a0a4";
      default:
        return "#3a9bdc";
    }
  };
 
  const getButtonBackgroundColor = (index) => {
    switch (index) {
      case 0:
        return "#274c77";
      case 1:
        return "#38b000";
      default:
        return "#274c77";
    }
  };


  function hadelSignOutClick(){
    localStorage.clear();
    navigate("/");
    window.location.reload()
  }
 
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: "#0065AB"
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <img
            src={logo}
            alt="ClearBiz Logo"
            style={{ height: "50px", marginRight: "16px" }}
          />
          <nav></nav>
        </Toolbar>
      </AppBar>
      {isSubscriptionActive === 'true' ? (
        <Grid container xs={'lg'} justifyContent={'center'}>
          <Grid>
            <div style={{ position: "absolute", top: 75, right: 0 }}>
              <div className="back">
                <button style={{ backgroundColor: "transparent", border: "none", color: "#012970", fontWeight: "bold" }} type="button" onClick={hadelSignOutClick} >SignOut</button>
              </div>
            </div>
          </Grid>
          <Grid item   mx={4} mt={1} >
            <h1 style={{fontWeight:'bold'}} className="text">Your Account And Payment Is In Review</h1>
          </Grid>
          <Grid item xs={'12'} sm={'10'} md={'6'}  alignContent={'center'}>
            <img  src={review3} alt="" className="payment"/>
          </Grid>
        </Grid>
      ) : (
        <>
          <Container
            disableGutters
            maxWidth="sm"
            component="main"
            sx={{ pt: 8, pb: 6 }}
          >
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Discover the Benefits of Our Plans
            </Typography>
 
           <Grid>
            <div style={{ position: "absolute", top: 75, right: 0 }}>
              <div className="back">
                <button style={{ backgroundColor: "transparent", border: "none", color: "#012970", fontWeight: "bold" }} type="button" onClick={hadelSignOutClick} >SignOut</button>
              </div>
            </div>
          </Grid>
 
            <Typography
              variant="subtitle1"
              align="center"
              color="error" // Set text color to red
              sx={{
                animation: "flash 1s infinite", // Add the flash animation
                "@keyframes flash": {
                  "0%": { opacity: 1 },
                  "50%": { opacity: 0 },
                  "100%": { opacity: 1 },
                },
              }}
            >
              {(isSubscriptionActive !== 'true') ? '***  Alert: Offer valid only for the first 500 Users ***' : "*** subscription is in process ****"}
            </Typography>
          </Container>
          <Container maxWidth="md" component="main">
            <Grid container spacing={5} justifyContent={'center'}>
              {subscriptionData?.result?.filter(item => item.is_active).map((item, index) =>
                item.type === 'CB Pro' && is_company === true ? null : (
                  <Grid item xs={12} sm={12} md={6} key={item.id}>
                    <SubscriptionCard
                      item={item}
                      index={index}
                      handleButtonClick={handleButtonClick}
                      isSubscriptionActive={isSubscriptionActive}
                      getcardBackgroundColor={getcardBackgroundColor}
                      getButtonBackgroundColor={getButtonBackgroundColor}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Container>
          <Termsandpolicy />
        </>
      )}
    </ThemeProvider>
  );
}
 
 
 
SubscriptionCard.propTypes = {
  item: PropTypes.object.isRequired,
  getcardBackgroundColor: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isSubscriptionActive: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  getButtonBackgroundColor: PropTypes.func.isRequired,
};
 