import Avatar from "@mui/material/Avatar";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import DropdownItems from "../../utils/dropdownutils";
import PropTypes from "prop-types";

const Ratings = ({ overallDetails, partnerName, partnersRatings }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#4b31ad" : "#ed4224",
    },
  }));

  const getRatingLabels = () => {
    let rLbls = [];
    const validfield = DropdownItems.score.slice(0, 10);

    rLbls = validfield.map((score, i) => {
      const ratingValue = overallDetails.overAllRatings[validfield.length - i];

      if (ratingValue > 0) {
        return (
          <div key={score.value}>
            <Typography
              className="bold"
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {score.label}: {ratingValue}
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={(ratingValue / partnersRatings.length) * 100}
            />
          </div>
        );
      }

      return null;
    });

    return rLbls;
  };

  const getAvatarColor = (avgRating) => {
    if (avgRating >= 7) return "green";
    if (avgRating >= 5) return "orange"; 
    if (avgRating >=1) return "red"; 
    return "grey"; 
  };

  return (
    <>
      <Typography className="com" style={{ fontSize: "18px", color: "#000" }}>
        <b>{`Overall Rating ${partnerName}`}</b>
      </Typography>

      <div style={{ textAlign: "center" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            style={{
              backgroundColor: getAvatarColor(overallDetails.avgRating),
              color: "white",
              width: "fit-content",
              height: "fit-content",
              minWidth: "40px",
              minHeight: "40px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
            }}
          >
            {isNaN(overallDetails.avgRating)
              ? "No Rating Yet"
              : overallDetails.avgRating}
          </Avatar>
        </div>

        <Typography className="riv" style={{ marginTop: "0px" }}>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            Total: {partnersRatings.length} Ratings
          </span>
        </Typography>
      </div>

      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          backgroundColor: "rgb(209,207,226)",
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        {getRatingLabels()}
      </div>
    </>
  );
};

Ratings.propTypes = {
  overallDetails: PropTypes.object.isRequired, // Changed from string to object
  partnerName: PropTypes.any.isRequired,
  partnersRatings: PropTypes.array.isRequired, // Changed from any to array
};

export default Ratings;
